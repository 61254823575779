import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import { graphql, StaticQuery } from 'gatsby';

import dataService from './data-service';

function SEO({ meta, keywords, title }) {
  return (
    <StaticQuery
      query={graphql`
        query {
          allMarkdownRemark(
            sort: { fields: [frontmatter___date], order: DESC }
            filter: { fileAbsolutePath: { regex: "/content/index.md/" } }
          ) {
            edges {
              node {
                frontmatter {
                  title
                  description
                  author
                }
              }
            }
          }
        }
      `}
      render={data => {
        const metaDescription =
          data.allMarkdownRemark.edges[0].node.frontmatter.description;
        const metaAuthor =
          data.allMarkdownRemark.edges[0].node.frontmatter.author;

        const { FAQ } = dataService;
        const FAQschema = [];

        for (const item of FAQ) {
          FAQschema.push({
            '@type': 'Question',
            name: item.question,
            acceptedAnswer: {
              '@type': 'Answer',
              text: item.answer
            }
          });
        }

        const schemaOrgJSONLD = [
          {
            '@context': 'https://schema.org',
            '@type': 'FAQPage',
            mainEntity: FAQschema
          }
        ];

        return (
          <Helmet
            htmlAttributes={{
              lang: 'en'
            }}
            title={title}
            meta={[
              {
                name: 'description',
                content: metaDescription
              },
              {
                property: 'og:title',
                content: title
              },
              {
                property: 'og:description',
                content: metaDescription
              },
              {
                property: 'og:type',
                content: 'website'
              },
              {
                name: 'twitter:card',
                content: 'summary'
              },
              {
                name: 'twitter:creator',
                content: metaAuthor
              },
              {
                name: 'twitter:title',
                content: title
              },
              {
                name: 'twitter:description',
                content: metaDescription
              }
            ]
              .concat(
                keywords.length > 0
                  ? {
                      name: 'keywords',
                      content: keywords.join(', ')
                    }
                  : []
              )
              .concat(meta)}
          >
            <script type='application/ld+json'>
              {JSON.stringify(schemaOrgJSONLD)}
            </script>
          </Helmet>
        );
      }}
    />
  );
}

SEO.defaultProps = {
  meta: [],
  keywords: []
};

SEO.propTypes = {
  description: PropTypes.string,
  meta: PropTypes.array,
  keywords: PropTypes.arrayOf(PropTypes.string),
  title: PropTypes.string.isRequired
};

export default SEO;
